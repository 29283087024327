<template>
  <div>
    <b-card class="card-custom mt-3 mb-3 card-titre sticky-top" no-body style="z-index: 25;">
      <template #header>
        <div class="card-title">
          <h3 class="card-label p-3 bg-primary text-white rounded">Distribution des leads</h3>
        </div>        
        <div class="card-title">          
          <b-button v-b-tooltip.hover title="Distribution par utilisateur" variant="primary" class="btn-icon mr-1" href="/#/prospect/stats" size="md">
            <i class="fas fa-list"></i>
          </b-button>
          <b-button v-if="$api.acl(10)" v-b-tooltip.hover title="Sauvegarder" variant="success" class="btn-icon" @click="save()" size="md">
            <i class="fas fa-size" :class="load ? 'fa-spinner fa-spin' : 'fa-save'"></i> 
          </b-button>
        </div>
      </template>
    </b-card>
    <b-row v-if="dist.length > 0">
      <b-col md="12" v-for="(d, i) in dist" :key="i" class="mb-3">
        <b-card class="card-search">
          <b-card-title>{{ d.titre }}</b-card-title>
          <b-table-simple hover>        
            <b-thead head-variant="light">              
              <b-tr>
                <b-th>Permanance</b-th>
                <b-th>Commericiaux</b-th>                
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-if="d.t_1 !== false">                
                <b-td>09h30 à 13h00</b-td>
                <b-td>
                  <InputForm type="select-model" :options="$api.params.User" v-model="d.t_1" :multiple="true"/> 
                </b-td>                
              </b-tr>
              <b-tr v-if="d.t_2 !== false">                
                <b-td>13h00 à 17h00</b-td>
                <b-td>
                  <InputForm type="select-model" :options="$api.params.User" v-model="d.t_2" :multiple="true"/> 
                </b-td>                
              </b-tr>
              <b-tr v-if="d.t_3 !== false">                
                <b-td>17h00 à 20h00</b-td>
                <b-td>
                  <InputForm type="select-model" :options="$api.params.User" v-model="d.t_3" :multiple="true"/> 
                </b-td>                
              </b-tr>              
            </b-tbody>            
          </b-table-simple>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import InputForm from '@/components/InputForm';
export default {
  name: "ClientNouveau",
  components: {InputForm},
  data(){
    return {
      load:false,
      dist:[]  
    }
  },
  watch:{
    
  },
  computed:{        
  },
  beforeMount() {    
  },
  mounted() {    
    this.init()
  },
  methods: {
    init(){
      if(this.load === true){
        return false;
      }
      this.load = true;
      this.$api.ajax('/lead/liste', null, res => {
        if(res.status === true){
          this.dist = res.data;
        }
        this.load = false;
      })
    },
    save(){
      if(this.load === true){
        return false;
      }
      this.load = true;
      var params = {
        dist: this.dist
      }
      this.$api.ajax('/lead/create', params, res => {
        if(res.status){
          this.dist = res.data
        }
        this.load = false;
      })
    }
  }
}
</script>
<style>

</style>
